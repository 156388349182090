@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;400;500&display=swap');

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

html {
    overflow-x: hidden;
}

/* Variables */
:root {
    /* Theme Colors */
    --main-light-color: rgb(255, 255, 255);
    --second-light-color: rgb(49, 49, 49);

    --main-dark-color: rgb(35, 35, 35);
    --second-dark-color: rgb(255, 255, 255);

    /* Sizes */
    --sm: 540px;
    --md: 720px;
    --lg: 960px;
    --xl: 1200px;
}

.bodySecThree {
    width: 100vw;
    /* padding-top: 1em; */
    display: flex;
    justify-content: center;
    font-family: 'Poppins', sans-serif;
}

.wrapUpSecThree {
    max-width: 360px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: .8em;
    text-align: justify;
}

.secThreeCardsContainer {
    min-width: 100%;
    margin-top: 1em;
}

@media screen and (min-width: 576px) {
    .wrapUpSecThree {
        min-width: var(--sm);
    }
    
    /* .secThreeCardsContainer {
        max-width: var(--sm);
    } */
}

@media screen and (min-width: 768px) {
    .wrapUpSecThree {
        min-width: var(--md);
    }
    
    .secThreeCardsContainer{
        /* max-width: var(--md); */
        display: flex;
    }
}

@media screen and (min-width: 992px) {
    .wrapUpSecThree {
        min-width: var(--lg);
    }
}

@media screen and (min-width: 1200px) {
    .wrapUpSecThree {
        max-width: var(--xl);
        padding-top: 2em;
    }
}

.secThreeDarkTheme {
    background-color: var(--main-dark-color);
    color: white;
}