@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;400;500&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

:root {
    --main-light-color: rgb(255, 255, 255);
    --second-light-color: rgb(49, 49, 49);

    --main-dark-color: rgb(35, 35, 35);
    --second-dark-color: rgb(255, 255, 255);

    /* Sizes */
    --sm: 540px;
    --md: 720px;
    --lg: 960px;
    --xl: 1200px;
}

.bodySecOne {
    width: 100vw;
    display: flex;
    justify-content: center;
}
/* Scroll Icon */
.scrollIconLight {
    width: 1.5rem;
    height: 3rem;
    border: 3px solid rgb(49, 49, 49);
    border-radius: 2em;
    display: flex;
    justify-content: center;
}
  
.scrollIconLight::before {
    content: '';
    background: rgb(49, 49, 49);
    display: block;
    width: 5px;
    height: 10px;
    border-radius: 2em;
    margin-top: 10%;
    animation: scrollDown 3s ease-in-out infinite;
    /* z-index: -1; */
}

.scrollIconDark {
    width: 1.5rem;
    height: 3rem;
    border: 3px solid white;
    border-radius: 2em;
    display: flex;
    justify-content: center;
}
  
.scrollIconDark::before {
    content: '';
    background: white;
    display: block;
    width: 5px;
    height: 10px;
    border-radius: 2em;
    margin-top: 10%;
    animation: scrollDown 3s ease-in-out infinite;
    /* z-index: -1; */
}


@keyframes scrollDown {
    0% {
      transform: translateY(10%)
    }
    50% {
      transform: translateY(280%)
    }
    100% {
      transform: translateY(10%)
    }
}

/* Picture */
.profilePicture {
    width: 12rem;
    height: 17rem;
    /* width: 57vw;
    height: 50vh; */
    border-radius: 6em;
    /* background-image: url(process.env.PUBLIC_URL + 'assets/profilePicture.png'); */
    /* background-size: cover; */
    /* process.env.PUBLIC_URL + 'assets/profilePicture.png' */
}

.profileBackgroundLight {
    background-color: white;
}

.profileBackgroundDark {
    background-color: rgb(255, 255, 255);
}

/* Fonts */
.profileName {
    font-family: 'Poppins', sans-serif;
}

.cvLink {
    text-decoration: none;
    color: inherit;
    font-family: 'Poppins', sans-serif;
}

/* Icons */
.iconSize {
    font-size: 1.875rem;
    line-height: 2.25rem;
}

.iconSize:hover {
    cursor: pointer;
}

/* Language Menu */
.languageCont {
    position: relative;
}

.languageMenu {
    list-style: none;
    background-color: rgb(245, 245, 245);
    color: black;
    /* padding: 1em; */
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 100%;
    left: -100%;
    font-family: 'Poppins', sans-serif;
}

.langItems {
    padding: .5em;
    font-weight: normal;
    padding: 1em;
}

.langItems:hover {
    cursor: pointer;
    background-color: rgb(87, 86, 86);
    color: white;

}

.showOn {
    opacity: 1;
    pointer-events: all;
}

/* .showOff {
    opacity: 0;
} */

/* Sub-Sections */
.subSecI {
    grid-area: icons;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
    /* background-color: red; */

}

.subSecII {
    grid-area: picture;
    /* background-color: green; */
    padding: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.subSecIII {
    grid-area: name;
    padding: 0.5rem;
    /* background-color: yellow; */
}

.subSecIV {
    grid-area: scrollDown;
    /* background-color: aquamarine; */
    padding: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.wrapUpSecOne {
    /* width: 100vw; */
    max-width: 360px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, auto);
    grid-template-areas: 
        "icons  picture picture picture"
        "name   name    name    name"
        "scrollDown scrollDown scrollDown scrollDown"
    ;
}

/* .darkTheme {
    background-color: rgb(36, 35, 35);
    color: white;
} */

@media screen and (min-width: 576px) {
    .wrapUpSecOne {
        min-width: var(--sm);
        grid-template-rows: repeat(7, auto);
        grid-template-columns: repeat(5, auto);
        grid-template-areas: 
            "name   name   picture picture  picture"
            "name   name   picture picture  picture"
            "name   name   picture picture  picture"
            "name   name   picture picture  picture"
            "icons  icons  picture picture  picture"
            "icons  icons  picture picture  picture"
            "scrollDown scrollDown scrollDown scrollDown scrollDown"
        ;
    }

    .subSecIII {
        /* display: flex; */
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }

    .subSecI {
        /* background-color: black; */
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .profileName {
        font-size: 2.3em;
    }

    .languageMenu {
        top: 0;
        left: 100%;
    }
}

@media screen and (min-width: 768px) {
    .wrapUpSecOne {
        min-width: var(--md);
        grid-template-columns: repeat(6, 1fr);
        grid-template-rows: repeat(7, auto);
        grid-template-areas: 
            "name   name   name picture picture picture"
            "name   name   name picture picture picture"
            "name   name   name picture picture picture"
            "name   name   name picture picture picture"
            "icons  icons  icons picture picture picture"
            "icons  icons  icons picture picture picture"
            "scrollDown scrollDown scrollDown scrollDown scrollDown scrollDown"
        ;
    }

    .profilePicture {
        width: 18rem;
        height: 25rem;
        border-radius: 7.8em;
    }

    .profileName {
        font-size: 3em;
    }
}

@media screen and (min-width: 992px) {
    
    .wrapUpSecOne {
        min-width: var(--lg);
        grid-template-columns: repeat(8, 1fr);
        grid-template-rows: repeat(8, auto);
        grid-template-areas: 
            "name  name  name  name  picture  picture  picture  picture"
            "name  name  name  name  picture  picture  picture  picture"
            "name  name  name  name  picture  picture  picture  picture"
            "name  name  name  name  picture  picture  picture  picture"
            "icons icons icons icons picture  picture  picture  picture"
            "icons icons icons icons picture  picture  picture  picture"
            "icons icons icons icons picture  picture  picture  picture"
            "scrollDown scrollDown scrollDown scrollDown scrollDown scrollDown scrollDown scrollDown"
        ;
    }

    .profilePicture {
        width: 21.5rem;
        height: 29rem;
    }

    .iconSize {
        font-size: 2.8rem;
    }
}


@media screen and (min-width: 1200px) {

    .wrapUpSecOne {
        min-width: var(--xl);
    }

    .profileName {
        font-size: 5em;
    }
}


.ligthTheme {
    background-color: var(--main-light-color);
    color: var(--second-light-color);
}

.darkTheme {
    background-color: var(--main-dark-color);
    color: var(--second-dark-color);
}