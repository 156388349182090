@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;400;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;400;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;400;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;400;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;400;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;400;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;400;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;400;500&display=swap);
/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

::-webkit-scrollbar {
  width: 12px;
  background: rgb(94, 93, 93);
}

::-webkit-scrollbar-thumb {
  background: rgb(138, 137, 137);
  border-radius: 6px;
}

/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* Variables */
:root {
    /* Theme Colors */
    --main-light-color: rgb(255, 255, 255);
    --second-light-color: rgb(49, 49, 49);

    --main-dark-color: rgb(35, 35, 35);
    --second-dark-color: rgb(255, 255, 255);

    /* Sizes */
    --sm: 540px;
    --md: 720px;
    --lg: 960px;
    --xl: 1200px;

    --nav--padding: 1em;
    --icon-size: 2.5rem;
}

.navContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 100;
}

/* Icons and Logo Style */
.logo {
    height: 10vh;
}

.iconSize {
    font-size: 2.5rem;
    font-size: var(--icon-size);
    line-height: 2.25rem;
}

.iconLogo {
    z-index: 1;
}

/* NavBar */
.navBar {
    /* min-width: 360px; */
    width: 90%;
    padding: 1em;
    padding: var(--nav--padding);
    background-color: inherit;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/* List - Links */
.linkList {
    width: 90%;
    /* min-width: 360px; */
    padding: .5em;
    background-color: inherit;
    list-style: none;
    position: absolute;
    top: 20px;
    text-align: center;
    /* top: calc(var(--icon-size) + var(--nav--padding)); */
    left: calc((100vw - 90%)/2);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0;
    pointer-events: none;
    transition: all 700ms ease-in-out;
}

.link {
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    text-decoration: none;
    color: inherit;
    font-weight: bold;
}

.link:hover {
    cursor: pointer;
}

.linkCont {
    margin: .8em;
}

/* Classes */
/* .showOff {
    opacity: 0;
    top: 20px;
    pointer-events: none;
} */

.showOn {
    opacity: 1;
    /* top: calc(var(--icon-size) + var(--nav--padding)); */
    top: calc(45px + 1em);
    top: calc(45px + var(--nav--padding));
    pointer-events: all;
}


/* Breaking Points */
@media screen and (min-width: 576px) {

    .navBar {
        min-width: 540px;
        min-width: var(--sm);
    }

    .linkList {
        width: 540px;
        width: var(--sm);
        left: calc((100vw - 540px)/2);
    }

}

@media screen and (min-width: 768px) {

    .navBar {
        all: revert;
        min-width: 720px;
        min-width: var(--md);
        padding: 1em;
        padding: var(--nav--padding);
        background-color: var(--main-color);
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .linkList {
        all: revert;
        list-style: none;
        width: 90%;
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        opacity: 1;
    }

    .iconMenu {
        display: none;
    }

}

@media screen and (min-width: 992px) {

    .navBar {
        min-width: 960px;
        min-width: var(--lg);
    }

}

@media screen and (min-width: 1200px) {

    body {
        display: flex;
        justify-content: center;
    }

    .navBar {
        min-width: 1200px;
        min-width: var(--xl);
    }

    .linkList {
        width: 95%;
    }

}

.ligthTheme {
    background-color: rgb(255, 255, 255);
    background-color: var(--main-light-color);
    color: rgb(49, 49, 49);
    color: var(--second-light-color);
}

.darkTheme {
    background-color: rgb(35, 35, 35);
    background-color: var(--main-dark-color);
    color: rgb(255, 255, 255);
    color: var(--second-dark-color);
}
.portfolioLogo {
    padding: 0;
    margin: 0;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    font-size: 1.2em;
    font-weight: bold;
    font-family: 'Poppins', sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
}

.portfolioLogo:hover {
    cursor: pointer;
}

.logoBorderLight {
    border: 2px solid rgb(49, 49, 49);
}

.logoBorderDark {
    border: 2px solid white;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

:root {
    --main-light-color: rgb(255, 255, 255);
    --second-light-color: rgb(49, 49, 49);

    --main-dark-color: rgb(35, 35, 35);
    --second-dark-color: rgb(255, 255, 255);

    /* Sizes */
    --sm: 540px;
    --md: 720px;
    --lg: 960px;
    --xl: 1200px;
}

.bodySecOne {
    width: 100vw;
    display: flex;
    justify-content: center;
}
/* Scroll Icon */
.scrollIconLight {
    width: 1.5rem;
    height: 3rem;
    border: 3px solid rgb(49, 49, 49);
    border-radius: 2em;
    display: flex;
    justify-content: center;
}
  
.scrollIconLight::before {
    content: '';
    background: rgb(49, 49, 49);
    display: block;
    width: 5px;
    height: 10px;
    border-radius: 2em;
    margin-top: 10%;
    -webkit-animation: scrollDown 3s ease-in-out infinite;
            animation: scrollDown 3s ease-in-out infinite;
    /* z-index: -1; */
}

.scrollIconDark {
    width: 1.5rem;
    height: 3rem;
    border: 3px solid white;
    border-radius: 2em;
    display: flex;
    justify-content: center;
}
  
.scrollIconDark::before {
    content: '';
    background: white;
    display: block;
    width: 5px;
    height: 10px;
    border-radius: 2em;
    margin-top: 10%;
    -webkit-animation: scrollDown 3s ease-in-out infinite;
            animation: scrollDown 3s ease-in-out infinite;
    /* z-index: -1; */
}


@-webkit-keyframes scrollDown {
    0% {
      -webkit-transform: translateY(10%);
              transform: translateY(10%)
    }
    50% {
      -webkit-transform: translateY(280%);
              transform: translateY(280%)
    }
    100% {
      -webkit-transform: translateY(10%);
              transform: translateY(10%)
    }
}


@keyframes scrollDown {
    0% {
      -webkit-transform: translateY(10%);
              transform: translateY(10%)
    }
    50% {
      -webkit-transform: translateY(280%);
              transform: translateY(280%)
    }
    100% {
      -webkit-transform: translateY(10%);
              transform: translateY(10%)
    }
}

/* Picture */
.profilePicture {
    width: 12rem;
    height: 17rem;
    /* width: 57vw;
    height: 50vh; */
    border-radius: 6em;
    /* background-image: url(process.env.PUBLIC_URL + 'assets/profilePicture.png'); */
    /* background-size: cover; */
    /* process.env.PUBLIC_URL + 'assets/profilePicture.png' */
}

.profileBackgroundLight {
    background-color: white;
}

.profileBackgroundDark {
    background-color: rgb(255, 255, 255);
}

/* Fonts */
.profileName {
    font-family: 'Poppins', sans-serif;
}

.cvLink {
    text-decoration: none;
    color: inherit;
    font-family: 'Poppins', sans-serif;
}

/* Icons */
.iconSize {
    font-size: 1.875rem;
    line-height: 2.25rem;
}

.iconSize:hover {
    cursor: pointer;
}

/* Language Menu */
.languageCont {
    position: relative;
}

.languageMenu {
    list-style: none;
    background-color: rgb(245, 245, 245);
    color: black;
    /* padding: 1em; */
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 100%;
    left: -100%;
    font-family: 'Poppins', sans-serif;
}

.langItems {
    padding: .5em;
    font-weight: normal;
    padding: 1em;
}

.langItems:hover {
    cursor: pointer;
    background-color: rgb(87, 86, 86);
    color: white;

}

.showOn {
    opacity: 1;
    pointer-events: all;
}

/* .showOff {
    opacity: 0;
} */

/* Sub-Sections */
.subSecI {
    grid-area: icons;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
    /* background-color: red; */

}

.subSecII {
    grid-area: picture;
    /* background-color: green; */
    padding: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.subSecIII {
    grid-area: name;
    padding: 0.5rem;
    /* background-color: yellow; */
}

.subSecIV {
    grid-area: scrollDown;
    /* background-color: aquamarine; */
    padding: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.wrapUpSecOne {
    /* width: 100vw; */
    max-width: 360px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, auto);
    grid-template-areas: 
        "icons  picture picture picture"
        "name   name    name    name"
        "scrollDown scrollDown scrollDown scrollDown"
    ;
}

/* .darkTheme {
    background-color: rgb(36, 35, 35);
    color: white;
} */

@media screen and (min-width: 576px) {
    .wrapUpSecOne {
        min-width: 540px;
        min-width: var(--sm);
        grid-template-rows: repeat(7, auto);
        grid-template-columns: repeat(5, auto);
        grid-template-areas: 
            "name   name   picture picture  picture"
            "name   name   picture picture  picture"
            "name   name   picture picture  picture"
            "name   name   picture picture  picture"
            "icons  icons  picture picture  picture"
            "icons  icons  picture picture  picture"
            "scrollDown scrollDown scrollDown scrollDown scrollDown"
        ;
    }

    .subSecIII {
        /* display: flex; */
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }

    .subSecI {
        /* background-color: black; */
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .profileName {
        font-size: 2.3em;
    }

    .languageMenu {
        top: 0;
        left: 100%;
    }
}

@media screen and (min-width: 768px) {
    .wrapUpSecOne {
        min-width: 720px;
        min-width: var(--md);
        grid-template-columns: repeat(6, 1fr);
        grid-template-rows: repeat(7, auto);
        grid-template-areas: 
            "name   name   name picture picture picture"
            "name   name   name picture picture picture"
            "name   name   name picture picture picture"
            "name   name   name picture picture picture"
            "icons  icons  icons picture picture picture"
            "icons  icons  icons picture picture picture"
            "scrollDown scrollDown scrollDown scrollDown scrollDown scrollDown"
        ;
    }

    .profilePicture {
        width: 18rem;
        height: 25rem;
        border-radius: 7.8em;
    }

    .profileName {
        font-size: 3em;
    }
}

@media screen and (min-width: 992px) {
    
    .wrapUpSecOne {
        min-width: 960px;
        min-width: var(--lg);
        grid-template-columns: repeat(8, 1fr);
        grid-template-rows: repeat(8, auto);
        grid-template-areas: 
            "name  name  name  name  picture  picture  picture  picture"
            "name  name  name  name  picture  picture  picture  picture"
            "name  name  name  name  picture  picture  picture  picture"
            "name  name  name  name  picture  picture  picture  picture"
            "icons icons icons icons picture  picture  picture  picture"
            "icons icons icons icons picture  picture  picture  picture"
            "icons icons icons icons picture  picture  picture  picture"
            "scrollDown scrollDown scrollDown scrollDown scrollDown scrollDown scrollDown scrollDown"
        ;
    }

    .profilePicture {
        width: 21.5rem;
        height: 29rem;
    }

    .iconSize {
        font-size: 2.8rem;
    }
}


@media screen and (min-width: 1200px) {

    .wrapUpSecOne {
        min-width: 1200px;
        min-width: var(--xl);
    }

    .profileName {
        font-size: 5em;
    }
}


.ligthTheme {
    background-color: rgb(255, 255, 255);
    background-color: var(--main-light-color);
    color: rgb(49, 49, 49);
    color: var(--second-light-color);
}

.darkTheme {
    background-color: rgb(35, 35, 35);
    background-color: var(--main-dark-color);
    color: rgb(255, 255, 255);
    color: var(--second-dark-color);
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* html, body {
    max-width: 100%;
    overflow-x: hidden;
} */

html {
    overflow-x: hidden;
}

/* Variables */
:root {
    /* Theme Colors */
    --main-light-color: rgb(255, 255, 255);
    --second-light-color: rgb(49, 49, 49);

    --main-dark-color: rgb(35, 35, 35);
    --second-dark-color: rgb(255, 255, 255);

    /* Sizes */
    --sm: 540px;
    --md: 720px;
    --lg: 960px;
    --xl: 1200px;
}

.bodySecTwo {
    width: 100vw;
    padding-top: 1em;
    display: flex;
    justify-content: center;
    font-family: 'Poppins', sans-serif;
}

.wrapUpSecTwo{
    max-width: 360px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: .8em;
    text-align: center;
}

.aboutMeTitle {
    margin-bottom: .3em;
}

.aboutMeText {
    margin-top: .3em;
    line-height: 2rem;
    font-size: 16px;
}

@media screen and (min-width: 576px) {
    .wrapUpSecTwo {
        min-width: 540px;
        min-width: var(--sm);
    }
}

@media screen and (min-width: 768px) {
    .wrapUpSecTwo {
        min-width: 720px;
        min-width: var(--md);
    }
    
    .aboutMeText {
        font-size: 21px;
    }
}

@media screen and (min-width: 992px) {
    .wrapUpSecTwo {
        min-width: 960px;
        min-width: var(--lg);
    }
}

@media screen and (min-width: 1200px) {
    .wrapUpSecTwo {
        max-width: 1200px;
        max-width: var(--xl);
        padding-top: 2em;
    }
}

.darkThemeSecTwo {
    background-color: rgb(35, 35, 35);
    background-color: var(--main-dark-color);
    color: rgb(255, 255, 255);
    color: var(--second-dark-color);
}
* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

html {
    overflow-x: hidden;
}

/* Variables */
:root {
    /* Theme Colors */
    --main-light-color: rgb(255, 255, 255);
    --second-light-color: rgb(49, 49, 49);

    --main-dark-color: rgb(35, 35, 35);
    --second-dark-color: rgb(255, 255, 255);

    /* Sizes */
    --sm: 540px;
    --md: 720px;
    --lg: 960px;
    --xl: 1200px;
}

.bodySecThree {
    width: 100vw;
    /* padding-top: 1em; */
    display: flex;
    justify-content: center;
    font-family: 'Poppins', sans-serif;
}

.wrapUpSecThree {
    max-width: 360px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: .8em;
    text-align: justify;
}

.secThreeCardsContainer {
    min-width: 100%;
    margin-top: 1em;
}

@media screen and (min-width: 576px) {
    .wrapUpSecThree {
        min-width: 540px;
        min-width: var(--sm);
    }
    
    /* .secThreeCardsContainer {
        max-width: var(--sm);
    } */
}

@media screen and (min-width: 768px) {
    .wrapUpSecThree {
        min-width: 720px;
        min-width: var(--md);
    }
    
    .secThreeCardsContainer{
        /* max-width: var(--md); */
        display: flex;
    }
}

@media screen and (min-width: 992px) {
    .wrapUpSecThree {
        min-width: 960px;
        min-width: var(--lg);
    }
}

@media screen and (min-width: 1200px) {
    .wrapUpSecThree {
        max-width: 1200px;
        max-width: var(--xl);
        padding-top: 2em;
    }
}

.secThreeDarkTheme {
    background-color: rgb(35, 35, 35);
    background-color: var(--main-dark-color);
    color: white;
}
* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

html {
    overflow-x: hidden;
}

/* Variables */
:root {
    /* Theme Colors */
    --main-light-color: rgb(255, 255, 255);
    --second-light-color: rgb(49, 49, 49);

    --main-dark-color: rgb(35, 35, 35);
    --second-dark-color: rgb(255, 255, 255);

    /* Sizes */
    --sm: 540px;
    --md: 720px;
    --lg: 960px;
    --xl: 1200px;
}

.cardContainer {
    /* max-width: calc(100vw - .8em); */
    max-width: 340px;
    /* width: 100%; */
    /* height: 150px; */
    /* margin: .5em; */
    margin-top: 1em;
    margin-bottom: 1.5em;
    /* background-color: blue; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.cardIconContainer {
    border-radius: 0 1em 1em 0;
    width: 40%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: gray; */
}

.cardContentContainer {
    font-family: 'Poppins', sans-serif;
    width: 60%;
    display: flex;
    flex-direction: column;
    padding-left: .5em;
}

.secThreeIconSize {
    font-size: 4rem;
}

.secThreeCardTitle {
    /* font-size: 5vw; */
    font-size: 18px;
}

.secThreeCardSkill{
    /* font-size: 4vw; */
    font-size: 16px;
}

@media screen and (min-width: 576px) {
    .cardContainer {
        /* max-width: calc(var(--sm) - .8em); */
        min-width: 540px;
        min-width: var(--sm);
        margin: 0;
        margin-top: 1em;
    }

    .cardIconContainer {
        width: 30%;
    }

    .cardContentContainer {
        width: 70%;
    }

    .secThreeCardTitle {
        /* font-size: 4vw; */
        font-size: 21px;
    }
}

@media screen and (min-width: 768px) {
    .cardContainer {
        /* min-width: calc((var(--md) - .8em)/2); */
        min-width: 360px;
        height: 150px;
        margin-bottom: 3em;
    }

    .secThreeIconSize {
        font-size: 3rem;
    }

    .secThreeCardTitle {
        /* font-size: 2vw; */
        font-size: 23px;
    }

    .secThreeCardSkill{
        /* font-size: 4vw; */
        font-size: 21px;
    }

    .secThreeIconSize {
        font-size: 4rem;
    }
    
}

@media screen and (min-width: 992px) {
    .cardContainer {
        min-width: 450px;
    }

    .secThreeIconSize {
        font-size: 5rem;
    }
}

@media screen and (min-width: 1200px) {
    .cardContainer {
        min-width: 600px;
        margin-top: 1.5em;
    }
}

.secThreeCardDarkTheme {
    background-color: rgb(35, 35, 35);
    background-color: var(--main-dark-color);
    color: rgb(255, 255, 255);
    color: var(--second-dark-color);
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    overflow-x: hidden;
}

/* Variables */
:root {
    /* Theme Colors */
    --main-light-color: rgb(255, 255, 255);
    --second-light-color: rgb(49, 49, 49);

    --main-dark-color: rgb(35, 35, 35);
    --second-dark-color: rgb(255, 255, 255);

    /* Sizes */
    --sm: 540px;
    --md: 720px;
    --lg: 960px;
    --xl: 1200px;

}

.secFourTitle {
    /* font-size: 16px; */
    font-family: 'Poppins', sans-serif;
}
  
.bodySecFour {
    width: 100%;
    padding-bottom: 1em;
    /* height: 100vh; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
  
.carouselContainer {
    width: 90vw;
  /*   height: 100vh; */
    display: flex;
    /* justify-content: center; */
    align-items: center;
    overflow: hidden;
    position: relative;
    /* border: 1px solid black; */
}
  
.carouselBody {
  /*   width: calc(150px + (.2rem * 2)); */
  /*   width: 150px; */
    display: flex;
    padding-top: 1em;
    padding-bottom: 1em;
    /* width: 100%; */
    /* transform: translateX(0);
    transition: transform 0.25s; */
}
  
.carouselItem {
    width: 90vw;
    /* height: 150px; */
    padding-top: 1em;
    /* background-color: red; */
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
  /*   margin: .2rem; */
}
  
.carouselItemImage {
    width: 80%;
    height: 150px;
    border-radius: 1em;
    background-color: white;
    object-fit: contain;
}

.carouselItemDescription {
    width: 80%;
    display: flex;
    flex-direction: column;
}

.carouselItemText {
    /* width: 80%; */
    font-family: 'Poppins', sans-serif;
    padding-top: 1em;
}

.carouselItemBtn {
    font-family: 'Poppins', sans-serif;
    align-self: flex-start;
    text-decoration: none;
    padding: .5em;
    background-color: rgb(8, 8, 8);
    color: white;
    border-radius: .2em;
    margin-top: .5em;
}

.carouselItemBtnLight {
    background-color: rgb(8, 8, 8);
    color: white;
}

.carouselItemBtnDark {
    background-color: white;
    color: black;
}
  
.carouselBtn {
    z-index: 1;
    position: absolute;
    font-size: 1.875rem;
}

.carouselBtn:hover {
    cursor: pointer;
}
  
.right {
    top: 50%;
    right: 0;
}
  
.left {
    top: 50%;
    left: 0;
}

.darkTheme {
    background-color: rgb(35, 35, 35);
    background-color: var(--main-dark-color);
    color: rgb(255, 255, 255);
    color: var(--second-dark-color);
}

@media screen and (min-width: 576px) {
    .bodySecFour {
        padding-top: 1em;
    }

    .carouselContainer {
        /* max-width: var(--sm); */
        max-width: 93.75vw;
    }

    .carouselItem {
        /* max-width: var(--sm); */
        max-width: 93.75vw;
    }

    .carouselItemImage {
        width: 50%;
        height: 160px;
    }
}

@media screen and (min-width: 768px) {

    .carouselContainer {
        /* max-width: var(--md); */
        max-width: 93.75vw;
    }

    .carouselItem {
        /* max-width: var(--md); */
        max-width: 93.75vw;
    }

    .carouselItemImage {
        width: 70%;
        height: 200px;
    }

    .carouselItemText {
        font-size: 21px;
    }
}

@media screen and (min-width: 992px) {

    .carouselContainer {
        max-width: 93.75vw;
        /* max-width: var(--lg); */
    }

    .carouselItem {
        max-width: 93.75vw;
        /* max-width: var(--lg); */
    }

    .carouselItemImage {
        width: 40%;
    }

    .carouselItemText {
        font-size: 20px;
        line-height: 2rem;
    }

    .right {
        top: 50%;
        right: 8%;
    }
      
    .left {
        top: 50%;
        left: 8%;
    }

}

@media screen and (min-width: 1200px) {

    .bodySecFour {
        padding-top: 2em;
    }

    .carouselContainer {
        /* max-width: 80vw; */
        padding-top: 1em;
        max-width: 80vw;
    }

    .carouselItem {
        /* max-width: 80vw; */
        max-width: 80vw;
    }
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* Variables */
:root {
    /* Theme Colors */
    --main-light-color: rgb(255, 255, 255);
    --second-light-color: rgb(49, 49, 49);

    --main-dark-color: rgb(35, 35, 35);
    --second-dark-color: rgb(255, 255, 255);

    /* Sizes */
    --sm: 540px;
    --md: 720px;
    --lg: 960px;
    --xl: 1200px;

}

.bodyFooter {
    width: 100%;
    /* background-color: red; */
    display: flex;
    justify-content: center;
    position: relative;
}

.wrapUpFooter {
    max-width: 360px;
    /* background-color: blue; */
    font-family: 'Poppins', sans-serif;
}

.contactContainer {
    width: 100%;
    /* height: 80%; */
    display: flex;
    flex-direction: column;
    padding-bottom: 1em;
}

.conatctInfo {
    width: 100%;
    /* background-color: brown; */
    padding: .5em 0 .5em 1.8em;
    line-height: 2rem;
}

.contactInfoMsg {
    padding: .5em 0;
}

.contactIcons {
    margin-right: 1em;
}

.socialMediaContainer {
    width: 100%;
    padding: .5em 0 .5em 1.8em;
    /* padding: .5em 1.8em; */
    /* background-color: blueviolet; */
}

.socialMediaIcons {
    display: flex;
}

.smIconsSize {
    font-size: 2rem;
    margin-top: .5em;
    margin-right: .5em;
}

.socialMediaIconLink {
    text-decoration: none;
    color: inherit;
}

.socialMediaIconLink:hover {
    cursor: pointer;
}

.creditsContainer {
    position: absolute;
    left: 0;
    top: 100%;
    width: 100vw;
    /* padding: .5em 0 .5em 1.8em; */
    padding: 1em 1.8em;
    /* margin-top: 1em; */
    display: flex;
    align-items: center;
}

.creditsContainerLight {
    background-color: rgb(241, 241, 240);
    color: black;
}

.creditsContainerDark {
    background-color: rgb(35, 35, 35);
    background-color: var(--main-dark-color);
    color: rgb(255, 255, 255);
    color: var(--second-dark-color);
}

.credits {
    font-size: 12px;
    padding-left: 1em;
}

.darkTheme {
    background-color: rgb(35, 35, 35);
    background-color: var(--main-dark-color);
    color: rgb(255, 255, 255);
    color: var(--second-dark-color);
}

@media screen and (min-width: 576px) {
    .wrapUpFooter {
        max-width: 540px;
        max-width: var(--sm);
    }

    .conatctInfo {
        padding-left: 0;
    }

    .socialMediaContainer {
        padding-left: 0;
    }

    .creditsContainer {
        padding-left: 2.6em;
    }
}

@media screen and (min-width: 768px) {
    .wrapUpFooter {
        min-width: 720px;
        min-width: var(--md);;
    }

    .contactContainer {
        flex-direction: row;
        /* justify-content: center; */
        align-items: center;
    }

    /* .conatctInfo {
        font-size: 21px;
    } */

    .socialMediaContainer {
        font-size: 21px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .smIconsSize {
        margin-right: .6em;
        margin-left: .6em;
    }
}

@media screen and (min-width: 992px) {
    .wrapUpFooter {
        min-width: 960px;
        min-width: var(--lg);
        padding: 3em 0;
    }

    .smIconsSize {
        font-size: 3rem;
    }

    .smIconsSize:hover {
        cursor: pointer;
    }
}

@media screen and (min-width: 1200px) {

    .bodyFooter {
        padding-top: 2em;
    }

    .wrapUpFooter {
        min-width: 1200px;
        min-width: var(--xl);
        /* padding-bottom: 0; */
    }

    .creditsContainer {
        /* max-width: var(--xl); */
        /* position: initial; */
        padding-left: calc((100vw - 1200px)/2);
    }
}
