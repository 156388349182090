@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;400;500&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* html, body {
    max-width: 100%;
    overflow-x: hidden;
} */

html {
    overflow-x: hidden;
}

/* Variables */
:root {
    /* Theme Colors */
    --main-light-color: rgb(255, 255, 255);
    --second-light-color: rgb(49, 49, 49);

    --main-dark-color: rgb(35, 35, 35);
    --second-dark-color: rgb(255, 255, 255);

    /* Sizes */
    --sm: 540px;
    --md: 720px;
    --lg: 960px;
    --xl: 1200px;
}

.bodySecTwo {
    width: 100vw;
    padding-top: 1em;
    display: flex;
    justify-content: center;
    font-family: 'Poppins', sans-serif;
}

.wrapUpSecTwo{
    max-width: 360px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: .8em;
    text-align: center;
}

.aboutMeTitle {
    margin-bottom: .3em;
}

.aboutMeText {
    margin-top: .3em;
    line-height: 2rem;
    font-size: 16px;
}

@media screen and (min-width: 576px) {
    .wrapUpSecTwo {
        min-width: var(--sm);
    }
}

@media screen and (min-width: 768px) {
    .wrapUpSecTwo {
        min-width: var(--md);
    }
    
    .aboutMeText {
        font-size: 21px;
    }
}

@media screen and (min-width: 992px) {
    .wrapUpSecTwo {
        min-width: var(--lg);
    }
}

@media screen and (min-width: 1200px) {
    .wrapUpSecTwo {
        max-width: var(--xl);
        padding-top: 2em;
    }
}

.darkThemeSecTwo {
    background-color: var(--main-dark-color);
    color: var(--second-dark-color);
}