@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;400;500&display=swap');

.portfolioLogo {
    padding: 0;
    margin: 0;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    font-size: 1.2em;
    font-weight: bold;
    font-family: 'Poppins', sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
}

.portfolioLogo:hover {
    cursor: pointer;
}

.logoBorderLight {
    border: 2px solid rgb(49, 49, 49);
}

.logoBorderDark {
    border: 2px solid white;
}