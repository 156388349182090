@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;400;500&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    overflow-x: hidden;
}

/* Variables */
:root {
    /* Theme Colors */
    --main-light-color: rgb(255, 255, 255);
    --second-light-color: rgb(49, 49, 49);

    --main-dark-color: rgb(35, 35, 35);
    --second-dark-color: rgb(255, 255, 255);

    /* Sizes */
    --sm: 540px;
    --md: 720px;
    --lg: 960px;
    --xl: 1200px;

}

.secFourTitle {
    /* font-size: 16px; */
    font-family: 'Poppins', sans-serif;
}
  
.bodySecFour {
    width: 100%;
    padding-bottom: 1em;
    /* height: 100vh; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
  
.carouselContainer {
    width: 90vw;
  /*   height: 100vh; */
    display: flex;
    /* justify-content: center; */
    align-items: center;
    overflow: hidden;
    position: relative;
    /* border: 1px solid black; */
}
  
.carouselBody {
  /*   width: calc(150px + (.2rem * 2)); */
  /*   width: 150px; */
    display: flex;
    padding-top: 1em;
    padding-bottom: 1em;
    /* width: 100%; */
    /* transform: translateX(0);
    transition: transform 0.25s; */
}
  
.carouselItem {
    width: 90vw;
    /* height: 150px; */
    padding-top: 1em;
    /* background-color: red; */
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
  /*   margin: .2rem; */
}
  
.carouselItemImage {
    width: 80%;
    height: 150px;
    border-radius: 1em;
    background-color: white;
    object-fit: contain;
}

.carouselItemDescription {
    width: 80%;
    display: flex;
    flex-direction: column;
}

.carouselItemText {
    /* width: 80%; */
    font-family: 'Poppins', sans-serif;
    padding-top: 1em;
}

.carouselItemBtn {
    font-family: 'Poppins', sans-serif;
    align-self: flex-start;
    text-decoration: none;
    padding: .5em;
    background-color: rgb(8, 8, 8);
    color: white;
    border-radius: .2em;
    margin-top: .5em;
}

.carouselItemBtnLight {
    background-color: rgb(8, 8, 8);
    color: white;
}

.carouselItemBtnDark {
    background-color: white;
    color: black;
}
  
.carouselBtn {
    z-index: 1;
    position: absolute;
    font-size: 1.875rem;
}

.carouselBtn:hover {
    cursor: pointer;
}
  
.right {
    top: 50%;
    right: 0;
}
  
.left {
    top: 50%;
    left: 0;
}

.darkTheme {
    background-color: var(--main-dark-color);
    color: var(--second-dark-color);
}

@media screen and (min-width: 576px) {
    .bodySecFour {
        padding-top: 1em;
    }

    .carouselContainer {
        /* max-width: var(--sm); */
        max-width: 93.75vw;
    }

    .carouselItem {
        /* max-width: var(--sm); */
        max-width: 93.75vw;
    }

    .carouselItemImage {
        width: 50%;
        height: 160px;
    }
}

@media screen and (min-width: 768px) {

    .carouselContainer {
        /* max-width: var(--md); */
        max-width: 93.75vw;
    }

    .carouselItem {
        /* max-width: var(--md); */
        max-width: 93.75vw;
    }

    .carouselItemImage {
        width: 70%;
        height: 200px;
    }

    .carouselItemText {
        font-size: 21px;
    }
}

@media screen and (min-width: 992px) {

    .carouselContainer {
        max-width: 93.75vw;
        /* max-width: var(--lg); */
    }

    .carouselItem {
        max-width: 93.75vw;
        /* max-width: var(--lg); */
    }

    .carouselItemImage {
        width: 40%;
    }

    .carouselItemText {
        font-size: 20px;
        line-height: 2rem;
    }

    .right {
        top: 50%;
        right: 8%;
    }
      
    .left {
        top: 50%;
        left: 8%;
    }

}

@media screen and (min-width: 1200px) {

    .bodySecFour {
        padding-top: 2em;
    }

    .carouselContainer {
        /* max-width: 80vw; */
        padding-top: 1em;
        max-width: 80vw;
    }

    .carouselItem {
        /* max-width: 80vw; */
        max-width: 80vw;
    }
}