@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;400;500&display=swap');

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

html {
    overflow-x: hidden;
}

/* Variables */
:root {
    /* Theme Colors */
    --main-light-color: rgb(255, 255, 255);
    --second-light-color: rgb(49, 49, 49);

    --main-dark-color: rgb(35, 35, 35);
    --second-dark-color: rgb(255, 255, 255);

    /* Sizes */
    --sm: 540px;
    --md: 720px;
    --lg: 960px;
    --xl: 1200px;
}

.cardContainer {
    /* max-width: calc(100vw - .8em); */
    max-width: 340px;
    /* width: 100%; */
    /* height: 150px; */
    /* margin: .5em; */
    margin-top: 1em;
    margin-bottom: 1.5em;
    /* background-color: blue; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.cardIconContainer {
    border-radius: 0 1em 1em 0;
    width: 40%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: gray; */
}

.cardContentContainer {
    font-family: 'Poppins', sans-serif;
    width: 60%;
    display: flex;
    flex-direction: column;
    padding-left: .5em;
}

.secThreeIconSize {
    font-size: 4rem;
}

.secThreeCardTitle {
    /* font-size: 5vw; */
    font-size: 18px;
}

.secThreeCardSkill{
    /* font-size: 4vw; */
    font-size: 16px;
}

@media screen and (min-width: 576px) {
    .cardContainer {
        /* max-width: calc(var(--sm) - .8em); */
        min-width: var(--sm);
        margin: 0;
        margin-top: 1em;
    }

    .cardIconContainer {
        width: 30%;
    }

    .cardContentContainer {
        width: 70%;
    }

    .secThreeCardTitle {
        /* font-size: 4vw; */
        font-size: 21px;
    }
}

@media screen and (min-width: 768px) {
    .cardContainer {
        /* min-width: calc((var(--md) - .8em)/2); */
        min-width: 360px;
        height: 150px;
        margin-bottom: 3em;
    }

    .secThreeIconSize {
        font-size: 3rem;
    }

    .secThreeCardTitle {
        /* font-size: 2vw; */
        font-size: 23px;
    }

    .secThreeCardSkill{
        /* font-size: 4vw; */
        font-size: 21px;
    }

    .secThreeIconSize {
        font-size: 4rem;
    }
    
}

@media screen and (min-width: 992px) {
    .cardContainer {
        min-width: 450px;
    }

    .secThreeIconSize {
        font-size: 5rem;
    }
}

@media screen and (min-width: 1200px) {
    .cardContainer {
        min-width: 600px;
        margin-top: 1.5em;
    }
}

.secThreeCardDarkTheme {
    background-color: var(--main-dark-color);
    color: var(--second-dark-color);
}