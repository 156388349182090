@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;400;500&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* Variables */
:root {
    /* Theme Colors */
    --main-light-color: rgb(255, 255, 255);
    --second-light-color: rgb(49, 49, 49);

    --main-dark-color: rgb(35, 35, 35);
    --second-dark-color: rgb(255, 255, 255);

    /* Sizes */
    --sm: 540px;
    --md: 720px;
    --lg: 960px;
    --xl: 1200px;

}

.bodyFooter {
    width: 100%;
    /* background-color: red; */
    display: flex;
    justify-content: center;
    position: relative;
}

.wrapUpFooter {
    max-width: 360px;
    /* background-color: blue; */
    font-family: 'Poppins', sans-serif;
}

.contactContainer {
    width: 100%;
    /* height: 80%; */
    display: flex;
    flex-direction: column;
    padding-bottom: 1em;
}

.conatctInfo {
    width: 100%;
    /* background-color: brown; */
    padding: .5em 0 .5em 1.8em;
    line-height: 2rem;
}

.contactInfoMsg {
    padding: .5em 0;
}

.contactIcons {
    margin-right: 1em;
}

.socialMediaContainer {
    width: 100%;
    padding: .5em 0 .5em 1.8em;
    /* padding: .5em 1.8em; */
    /* background-color: blueviolet; */
}

.socialMediaIcons {
    display: flex;
}

.smIconsSize {
    font-size: 2rem;
    margin-top: .5em;
    margin-right: .5em;
}

.socialMediaIconLink {
    text-decoration: none;
    color: inherit;
}

.socialMediaIconLink:hover {
    cursor: pointer;
}

.creditsContainer {
    position: absolute;
    left: 0;
    top: 100%;
    width: 100vw;
    /* padding: .5em 0 .5em 1.8em; */
    padding: 1em 1.8em;
    /* margin-top: 1em; */
    display: flex;
    align-items: center;
}

.creditsContainerLight {
    background-color: rgb(241, 241, 240);
    color: black;
}

.creditsContainerDark {
    background-color: var(--main-dark-color);
    color: var(--second-dark-color);
}

.credits {
    font-size: 12px;
    padding-left: 1em;
}

.darkTheme {
    background-color: var(--main-dark-color);
    color: var(--second-dark-color);
}

@media screen and (min-width: 576px) {
    .wrapUpFooter {
        max-width: var(--sm);
    }

    .conatctInfo {
        padding-left: 0;
    }

    .socialMediaContainer {
        padding-left: 0;
    }

    .creditsContainer {
        padding-left: 2.6em;
    }
}

@media screen and (min-width: 768px) {
    .wrapUpFooter {
        min-width: var(--md);;
    }

    .contactContainer {
        flex-direction: row;
        /* justify-content: center; */
        align-items: center;
    }

    /* .conatctInfo {
        font-size: 21px;
    } */

    .socialMediaContainer {
        font-size: 21px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .smIconsSize {
        margin-right: .6em;
        margin-left: .6em;
    }
}

@media screen and (min-width: 992px) {
    .wrapUpFooter {
        min-width: var(--lg);
        padding: 3em 0;
    }

    .smIconsSize {
        font-size: 3rem;
    }

    .smIconsSize:hover {
        cursor: pointer;
    }
}

@media screen and (min-width: 1200px) {

    .bodyFooter {
        padding-top: 2em;
    }

    .wrapUpFooter {
        min-width: var(--xl);
        /* padding-bottom: 0; */
    }

    .creditsContainer {
        /* max-width: var(--xl); */
        /* position: initial; */
        padding-left: calc((100vw - 1200px)/2);
    }
}