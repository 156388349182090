@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;400;500&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* Variables */
:root {
    /* Theme Colors */
    --main-light-color: rgb(255, 255, 255);
    --second-light-color: rgb(49, 49, 49);

    --main-dark-color: rgb(35, 35, 35);
    --second-dark-color: rgb(255, 255, 255);

    /* Sizes */
    --sm: 540px;
    --md: 720px;
    --lg: 960px;
    --xl: 1200px;

    --nav--padding: 1em;
    --icon-size: 2.5rem;
}

.navContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    position: sticky;
    top: 0;
    z-index: 100;
}

/* Icons and Logo Style */
.logo {
    height: 10vh;
}

.iconSize {
    font-size: var(--icon-size);
    line-height: 2.25rem;
}

.iconLogo {
    z-index: 1;
}

/* NavBar */
.navBar {
    /* min-width: 360px; */
    width: 90%;
    padding: var(--nav--padding);
    background-color: inherit;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/* List - Links */
.linkList {
    width: 90%;
    /* min-width: 360px; */
    padding: .5em;
    background-color: inherit;
    list-style: none;
    position: absolute;
    top: 20px;
    text-align: center;
    /* top: calc(var(--icon-size) + var(--nav--padding)); */
    left: calc((100vw - 90%)/2);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0;
    pointer-events: none;
    transition: all 700ms ease-in-out;
}

.link {
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    text-decoration: none;
    color: inherit;
    font-weight: bold;
}

.link:hover {
    cursor: pointer;
}

.linkCont {
    margin: .8em;
}

/* Classes */
/* .showOff {
    opacity: 0;
    top: 20px;
    pointer-events: none;
} */

.showOn {
    opacity: 1;
    /* top: calc(var(--icon-size) + var(--nav--padding)); */
    top: calc(45px + var(--nav--padding));
    pointer-events: all;
}


/* Breaking Points */
@media screen and (min-width: 576px) {

    .navBar {
        min-width: var(--sm);
    }

    .linkList {
        width: var(--sm);
        left: calc((100vw - 540px)/2);
    }

}

@media screen and (min-width: 768px) {

    .navBar {
        all: revert;
        min-width: var(--md);
        padding: var(--nav--padding);
        background-color: var(--main-color);
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .linkList {
        all: revert;
        list-style: none;
        width: 90%;
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        opacity: 1;
    }

    .iconMenu {
        display: none;
    }

}

@media screen and (min-width: 992px) {

    .navBar {
        min-width: var(--lg);
    }

}

@media screen and (min-width: 1200px) {

    body {
        display: flex;
        justify-content: center;
    }

    .navBar {
        min-width: var(--xl);
    }

    .linkList {
        width: 95%;
    }

}

.ligthTheme {
    background-color: var(--main-light-color);
    color: var(--second-light-color);
}

.darkTheme {
    background-color: var(--main-dark-color);
    color: var(--second-dark-color);
}